import axios from "axios";

const baseURL = "http://gmtool.silkroadmeta.com"
// const baseURL = "http://homeeffect.pk532.com/";
// const baseURL = "http://ltadmin.homeeffect.com"

const request = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {},
});

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.token = token;
    }
    if (localStorage.getItem("language")) {
      config.headers.language = localStorage.getItem("language");
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    }
  },
  function (error) {
    if (error.response.status === 500) {
      return {
        code: "500",
        data: {},
        message: "服务器错误，请稍后重试，或者联系系统管理员",
      };
    }
    return Promise.reject(error);
  }
);

export { baseURL };
export default request;
