import { createRouter, createWebHistory} from "vue-router";
import i18n from '@/locals/index.js'
const t = i18n.global.t

const routerHistory = createWebHistory();
import About from "@/views/about/about.vue";
import Core from "@/views/core/core.vue";
import SocialResponsibility from "@/views/socialResponsibility/socialResponsibility.vue";
import HotspotResponsibility from "@/views/hotspotResponsibility/hotspotResponsibility.vue";
import PrayerIndex from "@/views/prayer/index.vue";
import PrayerDetail from "@/views/prayer/detail.vue";
import ArtIndex from "@/views/art/index.vue";
import ArtDetail from "@/views/art/detail.vue";
import ArtMuseums from "@/views/art/museums.vue";
import ArtMuseumsSub from "@/views/art/subMuseums.vue";
import NftIndex from "@/views/nft/index.vue";
import NftDetail from "@/views/nft/detail.vue";
import Ecosystem from "@/views/ecosystem/index.vue"
import Support from "@/views/support/index.vue"
import Culture from "@/views/culture/index.vue"
import CultureCollection from "@/views/culture/collection.vue"
import CultureCollectionDetail from "@/views/culture/collectionDetail.vue"
import UserNotes from "@/views/user/notes.vue"
import User from "@/views/user/index.vue"
import UserEdit from "@/views/user/edit.vue"
import PublicDetails from "@/views/pubilcDetails/index.vue"
import GoodsDetails from "@/views/pubilcDetails/goodsDetails.vue"

import Login from "@/views/login/login.vue"
import Register from "@/views/login/register.vue"
import Forget from "@/views/login/forget.vue"

const router = createRouter({
    history: routerHistory,
    routes: [
        {
            path: "/",
            title: t("shouye"),
            meta: {
                keepAlive: true, 
            },
            component: function () {
                return import("../views/index/index.vue")
            }
        },{
            path: "/login",
            component: Login
        },{
            path: "/forget",
            component: Forget
        },{
            path: "/register",
            component: Register
        },{ // 关于我们
            path: "/about",
            meta: {
                title: [t("guanyuwomen")]
            },
            component: About
        },{ // 核心优势
            path: "/core",
            meta: {
                title: [t("hexinyoushi")]
            },
            component: Core
        },{ // 社会责任
            path: "/socialResponsibility",
            name: "socialResponsibility",
            meta: {
                title: [t("shehuizeren")]
            },
            component: SocialResponsibility
        },{ // 社会责任热点
            path: "/hotspotResponsibility",
            name: "hotspotResponsibility",
            meta: {
                title: [t("shehuizerenredian")]
            },
            component: HotspotResponsibility
        },{ // 祈福活动了解更多
            path: "/prayer",
            name: "prayer",
            meta: {
                title: [t("qifuhuodong")]
            },
            component: PrayerIndex
        },{ // 祈福活动详情
            path: "/prayer/detail",
            name: "prayerDetail",
            meta: {
                title: [t("qifuhuodong"), t("xiangqing")]
            },
            component: PrayerDetail
        },{ // 数字艺术馆了解更多
            path: "/art",
            name: "art",
            meta: {
                title: [t("shuziyishuguan")]
            },
            component: ArtIndex
        },{ // 数字艺术馆详情
            path: "/art/museums",
            name: "artMuseums",
            meta: {
                title: [t("shuziyishuguan"), t("bowuguan")]
            },
            component: ArtMuseums
        },{
            path: "/art/museums/sub",   
            name: "artMuseumsSub",
            meta: {
                title: [t("shuziyishuguan"), t("bowuguan"), t("xiangqing")]
            },
            component: ArtMuseumsSub
        },{ // 数字艺术馆
            path: "/art/museums/detail",
            name: "artMuseumsDetail",
            meta: {
                title: [t("shuziyishuguan"), t("bowuguan"), t("xiangqing")]
            },
            component: ArtDetail
        },{ // nft
            path: "/nft",
            name: "nft",
            meta: {
                title: [t("jingxuannft")]
            },
            component: NftIndex
        },{ // nft详情
            path: "/nft/detail",
            name: "nftDetail",
            meta: {
                title: [t("jingxuannft"), t("xiangqing")]
            },
            component: NftDetail
        },{ // 生态系统
            path: "/ecosystem",
            meta: {
                title: [t("shengtaixitongxiangqing")]
            },
            component: Ecosystem
        },{ // 丝路福持
            path: "/support",
            meta: {
                title: [t("fuchijijin")]
            },
            component: Support
        },{ // 文化使节
            path: "/culture",
            meta: {
                title: [t("wenhuashijie")]
            },
            component: Culture
        },{ // 文化使节藏品
            path: "/culture/collection",
            meta: {
                title: [t("wenhuashijie"), t("cangpinliebiao")]
            },
            component: CultureCollection
        },{ // 文化使节藏品详情
            path: "/culture/collection/detail",
            meta: {
                title: [t("wenhuashijie"), t("cangpinliebiao"), t("xiangqing")]
            },
            component: CultureCollectionDetail
        },{ // 慈善化身说明
            path: "/user/notes",
            meta: {
                title: [t("gerenxinxi"), t("shuoming")]
            },
            component: UserNotes
        },{ // 个人中心
            path: "/user",
            component: User
        },{ // 个人中心
            path: "/user/edit",
            component: UserEdit,
            meta: {
                keepAlive: true 
            },
            beforeEnter: (to, from, next) => {
                // 路由进入前的操作
                if(from.path === "/user/notes"){
                    to.meta.keepAlive = true
                }else{
                    to.meta.keepAlive = false
                }
                next()
            },
        },{ // 公用二级详情
            path: "/publicDetails",
            name: "prayerDetail",
            meta: {
                title: [t("qifuhuodong"), t("xiangqing")]
            },
            component: PublicDetails
        },{ // 公用商品详情
            path: "/goodsDetails",
            meta: {
                title: [t("qifuhuodong"), t("xiangqing")]
            },
            component: GoodsDetails
        }
    ]
})

export default router;
