export default {
    guanyuwomen: "关于我们",
    hezuohuoban: "合作伙伴",
    siluyuanzhuang: "丝路元宇宙",
    liaojiegengduo: "了解更多",
    dianjiqianwang: "点击前往",
    shouye: "首页",
    shehuizeren: "社会责任",
    cishanhuashen: "慈善化身",
    shanyuanyuzhou: "善元宇宙",
    wenhuashijie: "文化使节",
    fuchijijin: "福持基金",
    hexinyoushi: "核心优势",
    M2C2Bshengtaixitong: "M2C2B生态系统",
    shangyipian: "上一篇",
    xiayipian: "下一篇",
    anzhuobanxiazai: "安卓版下载",
    pingguobanxiazai: "苹果版下载",
    gonggao: "公告",
    nichen: "昵称",
    gerenjianjie: "个人简介",
    youjian: "邮件",
    xiugaixinxi: "修改信息",
    tuichudenglu: "退出登录",
    jieshao: '介绍',

    shehuizerenredian: "社会责任热点",
    qifuhuodong: "祈福活动",
    xiangqing: "详情",
    shuziyishuguan: "数字艺术馆",
    bowuguan: "博物馆",
    jingxuannft: "精选nft",
    shengtaixitongxiangqing: "生态系统详情",
    cangpinliebiao: "藏品列表",
    gerenxinxi: "个人信息",
    shuoming: "说明",
    juankuanrukou: "捐款入口",

    silutitle: "丝路公共数字艺术馆",
    siluquanqiu: '丝路全球文化使节',
    siluqifutitle: '丝路公益数字祈福活动',
    cishantitle: '慈善化身说明',
    silufuchijijin: '丝路福持基金',
    shengtaixitong: '生态系统',
    tesewenhuaguan: '特色文化馆',

    shehuiyiyi: "社会意义",
    huodong: "活动",
    fen: "份",
    jiarushijian: '加入时间',
    placeholder: '请输入活动关键字',
    fangshi: '方式',
    zhuangtai: '状态',
    leixing: '类型',
    quanbu: '全部',
    xianshanghuodong: '线上活动',
    xianxiahuodong: '线下活动',
    liandonghuodong: '联动活动',
    weikaishi: '未开始',
    jinxingzhong: '进行中',
    yijieshu: '已结束',
    placeholder2: '请输入关键字',
    placeholder3: '请输入昵称',
    liuchengtitle: '活动赠送藏品展示，点击查看领取流程',
    liucheng: '流程',
    huoqufangshi: '获取方式',
    huodongxiangqing: '活动详情',
    lishi: '历史',
    shiming: '使命',
    jijindeshiyong: '基金的使用',
    tesegongneng: '特色功能',
    quanqiumengguan: '全球各地盟馆',
    canyufangshi: '参与方式',
    zhanghaodenglu: '账号登录',
    shoujidenglu: '手机号登录',
    denglu: '登录',
    dianhua: '电话',
    youxiang: '邮箱',
    dizhi: '地址',
    bowuguanguanjianzi: '博物馆关键字',

    shurushoujihao: '请输入手机号',
    shuruxinmima: '请输入新密码',
    shuruquerenmima: '请确认新密码',
    shuruqyanzhengma: '请输入验证码',

    shurumima: '请输入密码',
    jieshaoziji: '介绍一下自己吧...',

    shuruzhanghao: '请输入账号',
    shurudenglumima: '请输入登录密码',
    shuruzaicimima: '请再次输入密码',
    shurudengluzhaohuimima: '用于登录和找回密码',

    gologinin: '去登录',
    yuedubingjieshou: '阅读并接受',
    yonghuxieyitext: '《丝路元宇宙用户协议》',
    yinsiceluetext: '《隐私政策》',

    fasongyanzhengma: '发送验证码',
    querenxiugai: '确认修改',
    chongxinfasongtime: '秒后重新发送',

    qingshuruguanjianzi: '请输入关键字',

    user: {
        xiugaixinxi: '修改信息',
        nicheng: '昵称',
        xingbie: '性别',
        cishanshezhi: '慈善化身设置',
        cishanshuoming: '慈善化身说明',
        gerenjianjie: '个人简介',
        shiyongzhong: '使用中',
        baocun: '保存',
        quxiao: '取消',
        nan: '男',
        nv: '女',
    }
};
