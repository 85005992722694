<template>
    <pageHeader/>
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb></breadcrumb>
        <detailHeader :info="detail"/>

        <div class="content">
            <div class="title">
                <img src="@/assets/qifu/title3.png" alt="">
                <p>{{ $t('jieshao') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="detail.content"/>
            </div>
            <div class="title">
                <img src="@/assets/qifu/title2.png" alt="">
                <p>{{ $t('huoqufangshi') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="detail.method"/>
            </div>
            <div class="title">
                <img src="@/assets/qifu/title1.png" alt="">
                <p>{{ $t('huodongxiangqing') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="detail.detail"/>
            </div>
            <!-- <enter></enter> -->
        </div>
    </div>
    <pageFooter/>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from '@/components/breadcrumb'
import detailHeader from '@/components/detailHeader'
import enter from "@/components/enter.vue"
import {getCultureDesignDetailApi} from "@/server/api"

export default {
    components: {
        pageHeader,
        pageFooter,
        breadcrumb,
        detailHeader,
        enter
    },
    data() {
        return {
            id: '',
            detail: {}
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.getCultureDetail()
    },
    methods: {
        async getCultureDetail() {
            const res = await getCultureDesignDetailApi({
                id: this.id
            })
            console.log('res', res);
            if (res.code === 200) {
                this.detail = res.data
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 20px 0 120px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }


    .content {
        margin: 200px auto 0;
        max-width: 1400px;

        .title {
            p {
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }

        .text {
            margin-top: 50px;
            margin-bottom: 100px;

            p {
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }
}
</style>